import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import GuestGuard from '../guards/GuestGuard';
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import NewPassword from '../pages/auth/NewPassword';
import VerifyCode from '../pages/auth/VerifyCode';
import useAuth from '../hooks/useAuth';
import AuthGuard from '../guards/AuthGuard';
import ConstantCreate from 'src/pages/constants/ConstantCreate';
import FaqCourse from 'src/components/FaqCourse';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard/index" replace />,
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/index" replace />, index: true },
        { path: 'index', element: <PageIndex /> },
        {
          path: 'user',
          children: [
            { element: <UserList />, index: true },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserCreate /> },
          ],
        },
        {
          path: 'dictionary_automoderation',
          children: [
            { element: <DictionaryAutoModerationList />, index: true },
            { path: 'new', element: <DictionaryAutoModerationCreate /> },
            { path: ':id/edit', element: <DictionaryAutoModerationCreate /> },
          ],
        },

        {
          path: 'referral_programme',
          children: [
            { element: <ReferralProgrammeList />, index: true },
            { path: 'new', element: <ReferralProgrammeCreate /> },
            { path: ':id/edit', element: <ReferralProgrammeCreate /> },
          ],
        },
        {
          path: 'comment',
          children: [
            { element: <Comments />, index: true },
            { path: 'new', element: <CommentsCreate /> },
            { path: ':id/edit', element: <CommentsCreate /> },
          ],
        },
        {
          path: 'story',
          children: [
            { element: <StoryList />, index: true },
            { path: 'new', element: <StoryCreate /> },
            { path: ':id/edit', element: <StoryCreate /> },
          ],
        },
        {
          path: 'promocode',
          children: [
            { element: <Promo />, index: true },
            { path: 'new', element: <PromocodeCreate /> },
            { path: ':id/edit', element: <PromocodeCreate /> },
          ],
        },
        {
          path: 'client',
          children: [
            { element: <ClientList />, index: true },
            { path: ':id', element: <ClientInform /> },
            { path: 'new', element: <ClientCreate /> },
            { path: ':id/edit', element: <ClientCreate /> },
          ],
        },
        {
          path: 'trainer',
          children: [
            { element: <TrainerList />, index: true },
            { path: 'new', element: <TrainerCreate /> },
            { path: ':id/edit', element: <TrainerCreate /> },
          ],
        },

        {
          path: 'product',
          children: [
            { element: <ProductList />, index: true },
            { path: 'new', element: <ProductCreate /> },
            { path: ':id/edit', element: <ProductCreate /> },
          ],
        },
        {
          path: 'promotion',
          children: [
            { element: <PromotionList />, index: true },
            { path: 'new', element: <PromotionCreate /> },
            { path: ':id/edit', element: <PromotionCreate /> },
          ],
        },
        {
          path: 'course',
          children: [
            { element: <CourseList />, index: true },
            { path: 'new', element: <CourseCreate /> },
            { path: ':id', element: <FaqCourse /> },
            { path: ':id/edit', element: <CourseCreate /> },
          ],
        },
        {
          path: 'workout',
          children: [
            { element: <WorkoutList />, index: true },
            { path: 'new', element: <WorkoutCreate /> },
            { path: ':id', element: <FaqCourse /> },
            { path: ':id/edit', element: <WorkoutCreate /> },
            { path: 'group/new', element: <GroupCreate /> },
            { path: 'group/:id/edit', element: <GroupCreate /> },
          ],
        },
        {
          path: 'complex',
          children: [
            { element: <ComplexList />, index: true },
            { path: 'new', element: <ComplexCreate /> },
            { path: ':id', element: <FaqCourse /> },
            { path: ':id/edit', element: <ComplexCreate /> },
          ],
        },
        {
          path: 'constant',
          children: [
            { element: <ConstantList />, index: true },
            { path: 'new', element: <ConstantCreate /> },
            { path: ':id/edit', element: <ConstantCreate /> },
          ],
        },
        {
          path: 'banner',
          children: [
            { element: <BannerList />, index: true },
            { path: 'new', element: <BannerCreate /> },
            { path: ':id/edit', element: <BannerCreate /> },
          ],
        },
        {
          path: 'group',
          children: [
            { element: <GroupsList />, index: true },
            { path: 'new', element: <GroupCreate /> },
            { path: ':id/edit', element: <GroupCreate /> },
          ],
        },
        {
          path: 'faq',
          children: [
            { element: <FaqList />, index: true },
            { path: 'new', element: <FaqCreate /> },
            { path: ':id/edit', element: <FaqCreate /> },
          ],
        },
        {
          path: 'socials',
          children: [
            { element: <SocialsList />, index: true },
            { path: 'new', element: <SocialsCreate /> },
            { path: ':id/edit', element: <SocialsCreate /> },
          ],
        },
        {
          path: 'grades',
          children: [
            { element: <GradesList />, index: true },
            { path: 'new', element: <GradesCreate /> },
            { path: ':id/edit', element: <GradesCreate /> },
          ],
        },
        {
          path: 'video',
          children: [{ element: <VideoList />, index: true }],
        },
        {
          path: 'order',
          children: [
            { element: <OrderList />, index: true },
            { path: 'new', element: <OrderCreate /> },
            { path: ':id/edit', element: <OrderCreate /> },
          ],
        },
        {
          path: 'version',
          children: [
            { element: <VersionList />, index: true },
            { path: 'new', element: <VersionCreate /> },
            { path: ':id/edit', element: <VersionCreate /> },
          ],
        },
        {
          path: 'countries',
          children: [
            { element: <CountryList />, index: true },
            { path: 'new', element: <CountryCreate /> },
            { path: ':id/edit', element: <CountryCreate /> },
          ],
        },
        {
          path: 'notification',
          children: [{ element: <NotificationList />, index: true }],
        },
        {
          path: 'send_push',
          children: [{ element: <SendPush />, index: true }],
        },
        {
          path: 'trigger_notification',
          children: [
            { element: <AgentList />, index: true },
            { path: 'new', element: <AgentCreate /> },
            { path: ':id/edit', element: <AgentCreate /> },
          ],
        },
        {
          path: 'analytics_load',
          children: [{ element: <AnalyticsLoadList />, index: true }],
        },
        {
          path: 'analytics_app',
          children: [{ element: <AnalyticsAppList />, index: true }],
        },
        {
          path: 'analytics_service',
          children: [{ element: <AnalyticsServiceList />, index: true }],
        },
        {
          path: 'analytics_administration_history',
          children: [{ element: <AdministrationHistoryList />, index: true }],
        },
        {
          path: 'usedesk',
          children: [
            { element: <UsedeskList />, index: true },
            { path: 'new', element: <UsedeskCreate /> },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const PageIndex = Loadable(lazy(() => import('../pages/PageIndex')));
const UserList = Loadable(lazy(() => import('../pages/user/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/user/UserCreate')));
const ClientInform = Loadable(lazy(() => import('../pages/client/ClientInform')));
const ClientList = Loadable(lazy(() => import('../pages/client/ClientList')));
const ClientCreate = Loadable(lazy(() => import('../pages/client/ClientCreate')));
const TrainerList = Loadable(lazy(() => import('../pages/trainer/TrainerList')));
const TrainerCreate = Loadable(lazy(() => import('../pages/trainer/TrainerCreate')));
const ProductList = Loadable(lazy(() => import('../pages/product/ProductList')));
const ProductCreate = Loadable(lazy(() => import('../pages/product/ProductCreate')));
const CourseList = Loadable(lazy(() => import('../pages/course/CourseList')));
const CourseCreate = Loadable(lazy(() => import('../pages/course/CourseCreate')));
const WorkoutList = Loadable(lazy(() => import('../pages/workout/WorkoutList')));
const WorkoutCreate = Loadable(lazy(() => import('../pages/workout/WorkoutCreate')));
const VideoList = Loadable(lazy(() => import('../pages/video/VideoList')));
const OrderList = Loadable(lazy(() => import('../pages/order/OrderList')));
const OrderCreate = Loadable(lazy(() => import('../pages/order/OrderCreate')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const ConstantList = Loadable(lazy(() => import('../pages/constants/ConstantList')));
const NotificationList = Loadable(lazy(() => import('../pages/notification/NotificationList')));
const SendPush = Loadable(lazy(() => import('../pages/notification/SendPush')));
const SocialsList = Loadable(lazy(() => import('../pages/socials/SocialsList')));
const SocialsCreate = Loadable(lazy(() => import('../pages/socials/SocialsCreate')));
const AnalyticsLoadList = Loadable(
  lazy(() => import('../pages/analytics/analyticsLoad/AnalyticsLoad'))
);
const AnalyticsAppList = Loadable(
  lazy(() => import('../pages/analytics/analyticsApp/AnalyticsAppList'))
);
const AnalyticsServiceList = Loadable(
  lazy(() => import('../pages/analytics/analyticsService/AnalyticsServiceList'))
);
const VersionList = Loadable(lazy(() => import('../pages/version/VersionList')));
const VersionCreate = Loadable(lazy(() => import('../pages/version/VersionCreate')));
const CountryList = Loadable(lazy(() => import('../pages/country/CountryList')));
const CountryCreate = Loadable(lazy(() => import('../pages/country/CountryCreate')));
const UsedeskCreate = Loadable(lazy(() => import('../pages/useDesk/UseDeskCreate')));
const UsedeskList = Loadable(lazy(() => import('../pages/useDesk/UseDeskList')));
const AgentList = Loadable(lazy(() => import('../pages/agent/AgentList')));
const AgentCreate = Loadable(lazy(() => import('../pages/agent/AgentCreate')));
const ComplexCreate = Loadable(lazy(() => import('../pages/complex/ComplexCreate')));
const ComplexList = Loadable(lazy(() => import('../pages/complex/ComplexList')));
const GradesList = Loadable(lazy(() => import('../pages/grades/GradesList')));
const GradesCreate = Loadable(lazy(() => import('../pages/grades/GradesCreate')));
const GroupsList = Loadable(lazy(() => import('../pages/group/GroupList')));
const GroupCreate = Loadable(lazy(() => import('../pages/group/GroupCreate')));
const AdministrationHistoryList = Loadable(
  lazy(() => import('../pages/AdministrationHistory/AdministrationHistoryList'))
);
const Comments = Loadable(lazy(() => import('../pages/comment/Comments')));
const CommentsCreate = Loadable(lazy(() => import('../pages/comment/CommentCreate')));
const DictionaryAutoModerationList = Loadable(
  lazy(() => import('../pages/dictionaryAutoModeration/DictionaryAutoModerationList'))
);
const DictionaryAutoModerationCreate = Loadable(
  lazy(() => import('../pages/dictionaryAutoModeration/DictionaryAutoModerationCreate'))
);
const ReferralProgrammeList = Loadable(
  lazy(() => import('../pages/referralProgramme/ReferralProgrammeList'))
);
const ReferralProgrammeCreate = Loadable(
  lazy(() => import('../pages/referralProgramme/ReferralProgrammeCreate'))
);
const Promo = Loadable(lazy(() => import('../pages/promocode/Promo')));
const PromocodeCreate = Loadable(lazy(() => import('../pages/promocode/PromocodeCreate')));
const PromotionCreate = Loadable(lazy(() => import('../pages/promotion/PromotionCreate')));
const PromotionList = Loadable(lazy(() => import('../pages/promotion/PromotionList')));
const BannerList = Loadable(lazy(() => import('../pages/banner/BannerList')));
const BannerCreate = Loadable(lazy(() => import('../pages/banner/BannerCreate')));
const StoryList = Loadable(lazy(() => import('../pages/story/StoryList')));
const StoryCreate = Loadable(lazy(() => import('../pages/story/StoryCreate')));
const FaqCreate = Loadable(lazy(() => import('../pages/faq/FaqCreate')));
const FaqList = Loadable(lazy(() => import('../pages/faq/FaqList')));
