import { Box } from '@mui/material';
import React, { createRef, useEffect, useState } from 'react';
import { ReactCropperElement, Cropper } from 'react-cropper';

interface IProps {
  onCropGetData: (data: any) => void;
  cropImg: string | undefined;
  initialCropBoxSize?: { width: number; height: number; x: number; y: number } | null;
  aspectRatio: number;
}

const getSizesCrop = (cropper: any) => {
  const ratioWidth = cropper?.canvasData?.naturalWidth / cropper?.containerData?.width;

  const ratioHeight = cropper?.canvasData?.naturalHeight / cropper?.containerData?.height;

  return { ratioWidth, ratioHeight };
};

const CropperImage = ({ onCropGetData, cropImg, initialCropBoxSize, aspectRatio }: IProps) => {
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });
  useEffect(() => {
    const img = new Image();
    img.onload = function () {
      const soot = img.height / img.width;
      if (img.width > 600 || img.height > 600) {
        setImgSize({ width: 300, height: 300 * soot });
      } else setImgSize({ width: img.width, height: img.height });
    };
    img.src = cropImg ?? '';
  }, [cropImg]);

  const cropperRef = createRef<ReactCropperElement>(); //не совпадают типы
  const [cropper, setCropper] = useState<any>(); // создал еще одну ссылку кропа, для получение даннх о картинке , т.е через cropperRef не получается передать

  useEffect(() => {
    if (cropper && cropperRef.current && initialCropBoxSize) {
      const { ratioHeight, ratioWidth } = getSizesCrop(cropper?.cropper);
      cropperRef.current?.cropper?.setCropBoxData({
        left: Math.ceil(initialCropBoxSize.x / ratioWidth),
        top: Math.ceil(initialCropBoxSize.y / ratioHeight),
        width: Math.ceil(initialCropBoxSize.width / ratioWidth),
        height: Math.ceil(initialCropBoxSize.height / ratioHeight),
      });
    }
  }, [cropperRef.current, initialCropBoxSize, cropper]);

  return (
    <div>
      {imgSize.height !== 0 && imgSize.width !== 0 && (
        <Box width={`${imgSize.width}px}`} height={`${imgSize.height}px}`} marginBottom="20px">
          <Cropper
            ref={cropperRef}
            onLoad={(e) => {
              setCropper(e.target);
            }}
            style={{ height: '100%', width: '100%' }}
            autoCrop={true}
            aspectRatio={aspectRatio}
            zoomOnWheel={false}
            initialAspectRatio={1}
            crop={(e) => {
              onCropGetData({
                x: Math.ceil(e.detail.x),
                y: Math.ceil(e.detail.y),
                width: Math.ceil(e.detail.width),
                height: Math.ceil(e.detail.height),
              });
            }}
            preview=".img-preview"
            src={cropImg}
            viewMode={1}
            minContainerWidth={10}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            guides={true}
          />
        </Box>
      )}
    </div>
  );
};

export default CropperImage;
