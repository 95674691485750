import jwtDecode from 'jwt-decode';
// routes
import { PATH_AUTH } from '../routes/paths';
//
import ApiClients from './axios';
import { ITokens } from 'src/@types/auth';

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

export const handleTokenExpired = () => {
  localStorage.removeItem('tokens');

  window.location.href = PATH_AUTH.login;
};

const setSession = (tokens: ITokens | null) => {
  if (tokens) {
    localStorage.setItem('tokens', JSON.stringify(tokens));
    Object.values(ApiClients).forEach(
      (instance) => (instance.defaults.headers.common.Authorization = tokens.access_token)
    );
  } else {
    localStorage.removeItem('tokens');
    Object.values(ApiClients).forEach(
      (instance) => delete instance.defaults.headers.common.Authorization
    );
  }
};

export { isValidToken, setSession };
