// components
import { SettingsValueProps } from './components/settings/type';

// API
// ----------------------------------------------------------------------

// local
//export const HOST_API = 'http://localhost/kolsanovafit-back-users/public/api/v1/admin/';
//export const HOST_VIDEO_API = 'http://localhost/kolsanovafit-back-video/public/api/v1/';

// test
export const HOST_API = 'https://back.test.kolsanovafit.ru/api/v1/admin/';
export const HOST_VIDEO_API = 'https://back-video.test.kolsanovafit.ru/api/v1/';
export const HOST_APP = 'https://back-app.test.kolsanovafit.ru/api/v1/admin/';
export const HOST_PUBLIC_APP = 'https://back-app.test.kolsanovafit.ru/api/v1/';
export const HOST_NOTIFICATIONS = 'https://back-notification.test.kolsanovafit.ru/api/v1/admin/';
export const HOST_PUBLIC_NOTIFICATIONS = 'https://back-notification.test.kolsanovafit.ru/api/v1/';
export const HOST_ANALYTICS = 'https://back-analytics.test.kolsanovafit.ru/api/v1/';
export const HOST_COMMENTS = 'https://back-comment.test.kolsanovafit.ru/api/v1/admin';
export const HOST_PUBLIC_COMMENTS = 'https://back-comment.test.kolsanovafit.ru/api/v1/';

// live
/*
export const HOST_API = 'https://back-users.app.kolsanovafit.ru/api/v1/admin/';
export const HOST_VIDEO_API = 'https://back-video.app.kolsanovafit.ru/api/v1/';
export const HOST_APP = 'https://back-app.app.kolsanovafit.ru/api/v1/admin/';
export const HOST_PUBLIC_APP = 'https://back-app.app.kolsanovafit.ru/api/v1/';
export const HOST_NOTIFICATIONS = 'https://back-notification.app.kolsanovafit.ru/api/v1/admin/';
export const HOST_PUBLIC_NOTIFICATIONS = 'https://back-notification.app.kolsanovafit.ru/api/v1/';
export const HOST_ANALYTICS = 'https://back-analytics.app.kolsanovafit.ru/api/v1/';
export const HOST_COMMENTS = 'https://back-comment.app.kolsanovafit.ru/api/v1/admin';
export const HOST_PUBLIC_COMMENTS = 'https://back-comment.app.kolsanovafit.ru/api/v1/';
*/

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'red',
  themeStretch: true,
};
