import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  ApproveCommentRequest,
  Comment,
  CommentGroup,
  CommentListParams,
  ParamsCommentChange,
  ParamsCommentCreate,
} from 'src/@types/comment';
import ApiClients from '../../utils/axios';
import { hasError } from '../slices/comment';
import { Pagination } from 'src/@types/common';

const { axiosComment } = ApiClients;

export const getCommentListThunk = createAsyncThunk(
  'comment/getCommentList',
  async (params: CommentListParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosComment.get<
        CommentListParams,
        AxiosResponse<{ data: Comment[]; meta: Pagination }>
      >('comments', {
        params,
      });

      return response.data;
    } catch (error) {
      dispatch(hasError(error));

      return rejectWithValue(error);
    }
  }
);

export const getCommentThunk = createAsyncThunk(
  'comment/getComment',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosComment.get<number, AxiosResponse<{ data: Comment }>>(
        `comments/${id}`
      );

      return response.data.data;
    } catch (error) {
      dispatch(hasError(error));

      return rejectWithValue(error);
    }
  }
);

export const editCommentThunk = createAsyncThunk(
  'comment/editComment',
  async ({ id, ...params }: ParamsCommentChange, { dispatch, rejectWithValue }) => {
    try {
      await axiosComment.put(`comments/${id}`, { ...params });

      return true;
    } catch (error) {
      dispatch(hasError(error));

      return rejectWithValue(error);
    }
  }
);

export const createCommentThunk = createAsyncThunk(
  'comment/createComment',
  async (params: ParamsCommentCreate, { dispatch, rejectWithValue }) => {
    try {
      await axiosComment.post(`comments`, {
        ...params,
      });

      return true;
    } catch (error) {
      dispatch(hasError(error));

      return rejectWithValue(error);
    }
  }
);

export const approveCommentThunk = createAsyncThunk(
  'comment/approveComment',
  async ({ ids, status }: ApproveCommentRequest, { rejectWithValue }) => {
    try {
      await axiosComment.put(`comments-bulk`, {
        ids,
        status,
      });

      return true;
    } catch (error) {
      return rejectWithValue(`Комментари${ids.length > 1 ? 'и' : 'й'} не удалось одобрить`);
    }
  }
);

export const deleteCommentThunk = createAsyncThunk(
  'comment/deleteComment',
  async (ids: number[], { dispatch, rejectWithValue }) => {
    try {
      await axiosComment.delete(`comments-bulk`, { data: { ids } });

      return true;
    } catch (error) {
      dispatch(hasError(error));
      return rejectWithValue(error);
    }
  }
);

export const getCommentGroupsThunk = createAsyncThunk(
  'comment/getCommentGroups',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosComment.get<AxiosResponse<CommentGroup[]>>('groups');

      return response.data.data;
    } catch (error) {
      dispatch(hasError(error));

      return rejectWithValue(error);
    }
  }
);
