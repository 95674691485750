import { GetValueObj } from 'src/components/animate/type';
// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export type ValueOfObjPath = GetValueObj<
  Omit<
    typeof PATH_DASHBOARD,
    | 'root'
    | 'index'
    | 'video'
    | 'marathon'
    | 'analyticsService'
    | 'analyticsApp'
    | 'socials'
    | 'administrationHistory'
  >
>;

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  index: path(ROOTS_DASHBOARD, '/index'),
  comment: {
    root: path(ROOTS_DASHBOARD, '/comment'),
    new: path(ROOTS_DASHBOARD, '/comment/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/comment/${id}/edit`),
  },
  story: {
    root: path(ROOTS_DASHBOARD, '/story'),
    new: path(ROOTS_DASHBOARD, '/story/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/story/${id}/edit`),
  },
  dictionaryAutoModeration: {
    root: path(ROOTS_DASHBOARD, '/dictionary_automoderation'),
    new: path(ROOTS_DASHBOARD, '/dictionary_automoderation/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/dictionary_automoderation/${id}/edit`),
  },
  referralProgramme: {
    root: path(ROOTS_DASHBOARD, '/referral_programme'),
    new: path(ROOTS_DASHBOARD, '/referral_programme/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/referral_programme/${id}/edit`),
  },
  promocode: {
    root: path(ROOTS_DASHBOARD, '/promocode'),
    new: path(ROOTS_DASHBOARD, '/promocode/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/promocode/${id}/edit`),
  },
  client: {
    root: path(ROOTS_DASHBOARD, '/client'),
    new: path(ROOTS_DASHBOARD, '/client/new'),
    inform: (id: number) => path(ROOTS_DASHBOARD, `/client/${id}`),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/client/${id}/edit`),
  },
  trainer: {
    root: path(ROOTS_DASHBOARD, '/trainer'),
    new: path(ROOTS_DASHBOARD, '/trainer/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/trainer/${id}/edit`),
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/product'),
    new: path(ROOTS_DASHBOARD, '/product/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/product/${id}/edit`),
  },
  promotion: {
    root: path(ROOTS_DASHBOARD, '/promotion'),
    new: path(ROOTS_DASHBOARD, '/promotion/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/promotion/${id}/edit`),
  },
  order: {
    root: path(ROOTS_DASHBOARD, '/order'),
    new: path(ROOTS_DASHBOARD, '/order/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/order/${id}/edit`),
  },
  version: {
    root: path(ROOTS_DASHBOARD, '/version'),
    new: path(ROOTS_DASHBOARD, '/version/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/version/${id}/edit`),
  },
  country: {
    root: path(ROOTS_DASHBOARD, '/countries'),
    new: path(ROOTS_DASHBOARD, '/countries/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/countries/${id}/edit`),
  },
  marathon: {
    root: path(ROOTS_DASHBOARD, '/marathon'),
    live: path(ROOTS_DASHBOARD, '/marathon/live'),
  },
  course: {
    root: path(ROOTS_DASHBOARD, '/course'),
    new: path(ROOTS_DASHBOARD, '/course/new'),
    faq: (id: number) => path(ROOTS_DASHBOARD, `/course/${id}`),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/course/${id}/edit`),
  },
  workout: {
    root: path(ROOTS_DASHBOARD, '/workout'),
    new: path(ROOTS_DASHBOARD, '/workout/new'),
    faq: (id: number) => path(ROOTS_DASHBOARD, `/course/${id}`),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/workout/${id}/edit`),
  },
  group: {
    root: path(ROOTS_DASHBOARD, '/group'),
    new: path(ROOTS_DASHBOARD, '/group/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/group/${id}/edit`),
  },
  complex: {
    root: path(ROOTS_DASHBOARD, '/complex'),
    new: path(ROOTS_DASHBOARD, '/complex/new'),
    faq: (id: number) => path(ROOTS_DASHBOARD, `/course/${id}`),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/complex/${id}/edit`),
  },

  faq: {
    root: path(ROOTS_DASHBOARD, '/faq'),
    new: path(ROOTS_DASHBOARD, '/faq/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/faq/${id}/edit`),
  },

  // group: {
  //   root: path(ROOTS_DASHBOARD, '/workout'),
  //   new: path(ROOTS_DASHBOARD, '/workout/group/new'),
  //   edit: (id: number) => path(ROOTS_DASHBOARD, `/workout/group/${id}/edit`),
  // },
  constant: {
    root: path(ROOTS_DASHBOARD, '/constant'),
    new: path(ROOTS_DASHBOARD, '/constant/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/constant/${id}/edit`),
  },
  banner: {
    root: path(ROOTS_DASHBOARD, '/banner'),
    new: path(ROOTS_DASHBOARD, '/banner/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/banner/${id}/edit`),
  },
  grades: {
    root: path(ROOTS_DASHBOARD, '/grades'),
    new: path(ROOTS_DASHBOARD, '/grades/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/grades/${id}/edit`),
  },
  triggerNotifications: {
    root: path(ROOTS_DASHBOARD, '/trigger_notification'),
    new: path(ROOTS_DASHBOARD, '/trigger_notification/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/trigger_notification/${id}/edit`),
  },
  socials: {
    root: path(ROOTS_DASHBOARD, '/socials'),
    new: path(ROOTS_DASHBOARD, '/socials/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/socials/${id}/edit`),
  },
  analyticsApp: {
    root: path(ROOTS_DASHBOARD, '/analytics_app'),
  },
  administrationHistory: {
    root: path(ROOTS_DASHBOARD, '/analytics_administration_history'),
  },
  analyticsService: {
    root: path(ROOTS_DASHBOARD, '/analytics_service'),
  },
  analyticsLoad: {
    root: path(ROOTS_DASHBOARD, '/analytics_load'),
  },
  useDesk: {
    root: path(ROOTS_DASHBOARD, '/usedesk'),
    new: path(ROOTS_DASHBOARD, '/usedesk/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/usedesk/${id}/edit`),
  },
  video: {
    root: path(ROOTS_DASHBOARD, '/video'),
  },
  notification: {
    root: path(ROOTS_DASHBOARD, '/notification'),
    new: path(ROOTS_DASHBOARD, '/notification/new'), //delete
    edit: (id: number) => path(ROOTS_DASHBOARD, `/notification/${id}/edit`), //delete
  },
  sendPush: {
    root: path(ROOTS_DASHBOARD, '/send_push'),
    new: path(ROOTS_DASHBOARD, '/notification/new'), //delete
    edit: (id: number) => path(ROOTS_DASHBOARD, `/notification/${id}/edit`), //delete
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
  },
};
