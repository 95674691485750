// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
  invoice: getIcon('ic_cart'),
  items: getIcon('ic_menu_item'),
  video: getIcon('ic_kanban'),
  analytics: getIcon('ic_analytics'),
};

export const navConfigAdmin = [
  {
    subheader: 'Общее',
    items: [
      {
        title: 'Словарь автомодерации',
        path: PATH_DASHBOARD.dictionaryAutoModeration.root,
        icon: ICONS.analytics,
      },
      {
        title: 'Реферальная программа',
        path: PATH_DASHBOARD.referralProgramme.root,
        icon: ICONS.analytics,
      },
      { title: 'Промокод', path: PATH_DASHBOARD.promocode.root, icon: ICONS.analytics },
      { title: 'Комментарии', path: PATH_DASHBOARD.comment.root, icon: ICONS.analytics },
      { title: 'Главная', path: PATH_DASHBOARD.index, icon: ICONS.dashboard },
      { title: 'Тренеры', path: PATH_DASHBOARD.trainer.root, icon: ICONS.user },
      { title: 'Клиенты', path: PATH_DASHBOARD.client.root, icon: ICONS.user },
      { title: 'Продукты', path: PATH_DASHBOARD.product.root, icon: ICONS.items },
      { title: 'Акции', path: PATH_DASHBOARD.promotion.root, icon: ICONS.items },
      { title: 'Счета и оплаты', path: PATH_DASHBOARD.order.root, icon: ICONS.invoice },
    ],
  },
  {
    subheader: 'Главная',
    items: [
      {
        title: 'История',
        path: PATH_DASHBOARD.story.root,
        icon: ICONS.analytics,
      },
      {
        title: 'Баннеры',
        path: PATH_DASHBOARD.banner.root,
        icon: ICONS.analytics,
      },
    ],
  },
  {
    subheader: 'Марафоны',
    items: [
      { title: 'Список марафонов', path: PATH_DASHBOARD.marathon.root, icon: ICONS.items },
      { title: 'Трансляции', path: PATH_DASHBOARD.marathon.live, icon: ICONS.video },
    ],
  },
  {
    subheader: 'Курсы и тренировки',
    items: [
      { title: 'Список курсов', path: PATH_DASHBOARD.course.root, icon: ICONS.items },
      { title: 'Список тренировок', path: PATH_DASHBOARD.workout.root, icon: ICONS.items },
      { title: 'Список комлексов', path: PATH_DASHBOARD.complex.root, icon: ICONS.items },
      { title: 'Список групп', path: PATH_DASHBOARD.group.root, icon: ICONS.items },
      { title: 'Видео', path: PATH_DASHBOARD.video.root, icon: ICONS.video },
      { title: 'Список FAQ', path: PATH_DASHBOARD.faq.root, icon: ICONS.items },
    ],
  },
  {
    subheader: 'Константы',
    items: [
      { title: 'Константы', path: PATH_DASHBOARD.constant.root, icon: ICONS.items },
      { title: 'Отзывы', path: PATH_DASHBOARD.grades.root, icon: ICONS.items },
    ],
  },
  {
    subheader: 'Социальный сети',
    items: [{ title: 'Социальный сети', path: PATH_DASHBOARD.socials.root, icon: ICONS.items }],
  },
  {
    subheader: 'Уведомления',
    items: [
      { title: 'уведомления', path: PATH_DASHBOARD.notification.root, icon: ICONS.items },
      { title: 'отправить пуш', path: PATH_DASHBOARD.sendPush.root, icon: ICONS.items },
      {
        title: 'триггерные уведомления',
        path: PATH_DASHBOARD.triggerNotifications.root,
        icon: ICONS.items,
      },
    ],
  },
  {
    subheader: 'Аналитика',
    items: [
      {
        title: 'История администрирования',
        path: PATH_DASHBOARD.administrationHistory.root,
        icon: ICONS.analytics,
      },
      { title: 'Нагрузка', path: PATH_DASHBOARD.analyticsLoad.root, icon: ICONS.analytics },
      { title: 'Приложение', path: PATH_DASHBOARD.analyticsApp.root, icon: ICONS.analytics },
      { title: 'Запросы', path: PATH_DASHBOARD.analyticsService.root, icon: ICONS.analytics },
      { title: 'Usedesk', path: PATH_DASHBOARD.useDesk.root, icon: ICONS.analytics },
    ],
  },
  {
    subheader: 'Версии',
    items: [{ title: 'Версии', path: PATH_DASHBOARD.version.root, icon: ICONS.items }],
  },
  {
    subheader: 'Страны',
    items: [{ title: 'Страны', path: PATH_DASHBOARD.country.root, icon: ICONS.items }],
  },
  {
    subheader: 'Администрирование',
    items: [
      {
        title: 'Пользователи',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
      },
    ],
  },
];

export const navConfigGuest = [
  {
    subheader: 'Общее',
    items: [
      { title: 'Главная', path: PATH_DASHBOARD.index, icon: ICONS.dashboard },
      { title: 'Тренеры', path: PATH_DASHBOARD.trainer.root, icon: ICONS.user },
    ],
  },
  {
    subheader: 'Марафоны',
    items: [
      { title: 'Список марафонов', path: PATH_DASHBOARD.marathon.root, icon: ICONS.items },
      { title: 'Трансляции', path: PATH_DASHBOARD.marathon.live, icon: ICONS.video },
    ],
  },
  {
    subheader: 'Курсы и тренировки',
    items: [
      { title: 'Список курсов', path: PATH_DASHBOARD.course.root, icon: ICONS.items },
      { title: 'Список тренировок', path: PATH_DASHBOARD.workout.root, icon: ICONS.items },
      { title: 'Список комлексов', path: PATH_DASHBOARD.complex.root, icon: ICONS.items },
      { title: 'Список групп', path: PATH_DASHBOARD.group.root, icon: ICONS.items },
      { title: 'Список FAQ', path: PATH_DASHBOARD.faq.root, icon: ICONS.items },
    ],
  },
  {
    subheader: 'Константы',
    items: [{ title: 'Отзывы', path: PATH_DASHBOARD.grades.root, icon: ICONS.items }],
  },
];

export const navConfigSupport = [
  {
    subheader: 'Общее',
    items: [
      {
        title: 'Словарь автомодерации',
        path: PATH_DASHBOARD.dictionaryAutoModeration.root,
        icon: ICONS.analytics,
      },
      { title: 'Промокод', path: PATH_DASHBOARD.promocode.root, icon: ICONS.analytics },
      { title: 'Комментарии', path: PATH_DASHBOARD.comment.root, icon: ICONS.analytics },
      { title: 'Главная', path: PATH_DASHBOARD.index, icon: ICONS.dashboard },
      { title: 'Клиенты', path: PATH_DASHBOARD.client.root, icon: ICONS.user },
      { title: 'Счета и оплаты', path: PATH_DASHBOARD.order.root, icon: ICONS.invoice },
    ],
  },
  {
    subheader: 'Марафоны',
    items: [
      { title: 'Список марафонов', path: PATH_DASHBOARD.marathon.root, icon: ICONS.items },
      { title: 'Трансляции', path: PATH_DASHBOARD.marathon.live, icon: ICONS.video },
    ],
  },
  {
    subheader: 'Курсы и тренировки',
    items: [
      { title: 'Список курсов', path: PATH_DASHBOARD.course.root, icon: ICONS.items },
      { title: 'Список тренировок', path: PATH_DASHBOARD.workout.root, icon: ICONS.items },
      { title: 'Список комлексов', path: PATH_DASHBOARD.complex.root, icon: ICONS.items },
      { title: 'Список групп', path: PATH_DASHBOARD.group.root, icon: ICONS.items },
      { title: 'Список FAQ', path: PATH_DASHBOARD.faq.root, icon: ICONS.items },
    ],
  },
  {
    subheader: 'Константы',
    items: [{ title: 'Отзывы', path: PATH_DASHBOARD.grades.root, icon: ICONS.items }],
  },
];

export const navConfigModerator = [
  {
    subheader: 'Общее',
    items: [
      {
        title: 'Словарь автомодерации',
        path: PATH_DASHBOARD.dictionaryAutoModeration.root,
        icon: ICONS.analytics,
      },
      {
        title: 'Реферальная программа',
        path: PATH_DASHBOARD.referralProgramme.root,
        icon: ICONS.analytics,
      },
      { title: 'Комментарии', path: PATH_DASHBOARD.comment.root, icon: ICONS.analytics },
      { title: 'Главная', path: PATH_DASHBOARD.index, icon: ICONS.dashboard },
      { title: 'Клиенты', path: PATH_DASHBOARD.client.root, icon: ICONS.user },
      { title: 'Продукты', path: PATH_DASHBOARD.product.root, icon: ICONS.items },
      { title: 'Счета и оплаты', path: PATH_DASHBOARD.order.root, icon: ICONS.invoice },
    ],
  },
  {
    subheader: 'Марафоны',
    items: [
      { title: 'Список марафонов', path: PATH_DASHBOARD.marathon.root, icon: ICONS.items },
      { title: 'Трансляции', path: PATH_DASHBOARD.marathon.live, icon: ICONS.video },
    ],
  },
  {
    subheader: 'Курсы и тренировки',
    items: [
      { title: 'Список курсов', path: PATH_DASHBOARD.course.root, icon: ICONS.items },
      { title: 'Список тренировок', path: PATH_DASHBOARD.workout.root, icon: ICONS.items },
      { title: 'Список комлексов', path: PATH_DASHBOARD.complex.root, icon: ICONS.items },
      { title: 'Список групп', path: PATH_DASHBOARD.group.root, icon: ICONS.items },
      { title: 'Список FAQ', path: PATH_DASHBOARD.faq.root, icon: ICONS.items },
    ],
  },
  {
    subheader: 'Константы',
    items: [{ title: 'Отзывы', path: PATH_DASHBOARD.grades.root, icon: ICONS.items }],
  },
];
