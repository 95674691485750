import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Comment, CommentState } from 'src/@types/comment';
import {
  approveCommentThunk,
  createCommentThunk,
  deleteCommentThunk,
  editCommentThunk,
  getCommentGroupsThunk,
  getCommentListThunk,
  getCommentThunk,
} from '../thunks/comment';
import { Pagination } from 'src/@types/common';

const initialState: CommentState = {
  isLoading: false,
  comments: [],
  meta: null,
  commentListParams: {
    sort: 'desc',
    sort_by: 'updated_at',
  },
  comment: null,
  commentGroups: [],
};

const slice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetError(state) {
      state.error = undefined;
    },
    resetComment(state) {
      state.comment = null;
    },
    setCommentListParams(state, action) {
      state.commentListParams = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      // getCommentListThunk
      .addCase(getCommentListThunk.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(
        getCommentListThunk.fulfilled,
        (state, { payload }: PayloadAction<{ data: Comment[]; meta: Pagination }>) => {
          state.isLoading = false;
          state.comments = payload.data;
          state.meta = payload.meta;
        }
      )
      .addCase(getCommentListThunk.rejected, (state, { payload }: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = payload;
      })

      // getCommentThunk
      .addCase(getCommentThunk.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getCommentThunk.fulfilled, (state, { payload }: PayloadAction<Comment>) => {
        state.isLoading = false;
        state.comment = payload;
      })
      .addCase(getCommentThunk.rejected, (state, { payload }: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = payload;
      })

      // editCommentThunk
      .addCase(editCommentThunk.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(editCommentThunk.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(editCommentThunk.rejected, (state, { payload }: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = payload;
      })

      // createCommentThunk
      .addCase(createCommentThunk.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(createCommentThunk.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createCommentThunk.rejected, (state, { payload }: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = payload;
      })

      // approveCommentThunk
      .addCase(approveCommentThunk.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(approveCommentThunk.fulfilled, (state, { meta }) => {
        state.isLoading = false;

        const { ids, status } = meta.arg;

        state.comments = state.comments.map((comment) => {
          if (ids.some((id) => id === comment.id)) {
            comment.status = status;
          }

          return comment;
        });
      })
      .addCase(approveCommentThunk.rejected, (state, { payload }: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = payload;
      })

      // deleteCommentThunk
      .addCase(deleteCommentThunk.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(deleteCommentThunk.fulfilled, (state, { meta }) => {
        state.isLoading = false;
        const ids = meta.arg;

        state.comments = state.comments.filter((comment) => !ids.some((id) => id === comment.id));
      })
      .addCase(deleteCommentThunk.rejected, (state, { payload }: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = payload;
      })

      // getCommentGroupsThunk
      .addCase(getCommentGroupsThunk.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getCommentGroupsThunk.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.isLoading = false;
        state.commentGroups = payload;
      })
      .addCase(getCommentGroupsThunk.rejected, (state, { payload }: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { hasError, resetError, resetComment, setCommentListParams } = slice.actions;
