// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

type IProps = {
  name: string;
};

type Props = IProps & TextFieldProps;

export default function RHFDatePicker({ name, label }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DateTimePicker
          label={label}
          inputFormat="dd.MM.yyyy HH:mm"
          value={field.value ?? null}
          onChange={(v) => {
            const date =
              v instanceof Date
                ? new Date(
                    Date.UTC(
                      v.getFullYear(),
                      v.getMonth(),
                      v.getDate(),
                      v.getHours() - 7,
                      v.getMinutes()
                    )
                  )
                : v;

            field.onChange(date);
          }}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField {...params} fullWidth error={!!error} helperText={error?.message} />
          )}
        />
      )}
    />
  );
}
